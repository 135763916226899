<template>
  <div>
    <el-card>
      <el-row :gutter="20">
        <el-col el-col :xs="15" :sm="18" :md="20" :lg="20" :xl="20">
          <div class="head-container">
            <el-input v-model="queryInfo.query" style="width: 300px;" @keyup.enter.native="getTableData" clearable  placeholder="请输入内容"  >
              <el-button slot="append" icon="el-icon-search"  @click="getTableData"></el-button>
            </el-input>
            <span style="margin-left: 5px;"></span>
            <el-date-picker style="width:120px"
              v-model="queryInfo.query_YEAR"
              type="year"
              :clearable="false"
              value-format="yyyy"
              @change="getTableData"
              placeholder="财务年份查询">
            </el-date-picker>
            <span style="margin-left: 5px;"></span>
            <el-select style="width:108px" v-model="queryInfo.querySelectInput" @change="getTableData" placeholder="条件选择">
              <el-option
                v-for="item in querySelectOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <span style="margin-left: 5px;"></span>
            <el-select style="width:108px" v-model="queryInfo.is_CHECK" @change="getTableData" placeholder="任务核查">
              <el-option
                v-for="item in queryCheckOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <span style="margin-left: 5px;"></span>
            <el-select style="width:138px" v-model="queryInfo.is_CITY_INVEST" @change="getTableData" placeholder="上年是否城投">
              <el-option
                v-for="item in queryCityOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <!-- <span style="margin-left: 5px;"></span>
            <el-select style="width:108px" v-model="queryInfo.bond_RAISE" @change="getTableData" placeholder="存续债">
              <el-option
                v-for="item in bondRaiseOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select> -->
            <span style="margin-left: 5px;"></span>
            <el-button type="primary" @click="comfirmGics" :disabled="isDisabledGics">确认行业</el-button>
            <el-button type="primary" @click="comfirmGics" :disabled="isDisabledVio">确认城投</el-button>
            <el-button type="primary" @click="comfirmGics" :disabled="isTaskConfirm">任务提交</el-button>
          </div>
        </el-col>
      </el-row>
      <!-- 用户列表区域 -->
      <el-table
        :data="tableData"  highlight-current-row
        @current-change="companyCurrentChange"
        border stripe
        style="width: 100%">
        <el-table-column
          fixed
          prop="company_ID"
          label="公司ID"
          width="150">
        </el-table-column>
        <el-table-column
          prop="company_NAME"
          label="公司名称"
          width="350">
        </el-table-column>
        <el-table-column
          prop="fiscal_YEAR"
          label="财报年份"
          width="140">
        </el-table-column>
        <!-- <el-table-column
          prop="create_TIME"
          label="创建时间"
          width="280">
        </el-table-column>
        <el-table-column
          prop="update_TIME"
          label="更新时间"
          width="280">
        </el-table-column> -->
        <el-table-column
          prop="task_STATUS_NAME"
          label="任务状态"
          width="150">
        </el-table-column>
        <el-table-column
          prop="process_OPINION"
          label="意见"
          width="150" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
          prop="process_PERSON"
          label="退回人"
          width="150" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
          label="操作"
          width="100">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text">变更记录</el-button>
          </template>
        </el-table-column>
        <el-table-column
          v-if="queryInfo.querySelectInput != 'back'"
          label="暂不处理"
          width="100">
          <template slot-scope="scope">
            <el-button @click="suspendClick(scope.row)" type="text">确定</el-button>
          </template>
        </el-table-column>
        <el-table-column v-if="queryInfo.querySelectInput === 'gics' & queryInfo.is_CHECK === 'suspend'"
          prop="suspend_GICS_PERSION"
          label="暂不处理行业人"
          width="150" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column v-if="queryInfo.querySelectInput === 'violate' & queryInfo.is_CHECK === 'suspend'"
          prop="suspend_CITY_PERSION"
          label="暂不处理城投人"
          width="150" :show-overflow-tooltip="true">
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum"
        :page-sizes="[2, 5, 10, 15]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totle"
      ></el-pagination>
    </el-card>
    <!-- 添加用户的对话框 -->
    <el-dialog title="主体信息修改" :visible.sync="addDialogVisible" width="50%" @close="addDialogClosed"
      :before-close="closeRegDialog">
      <el-form ref="addChangeForm" :model="addChangeForm" label-width="100px" :rules="rules" class="demo-ruleForm" >
        <el-form-item label="公司名称 :"  >
          <el-row>
            <el-col :span="11">
              {{addChangeForm.company_NAME}}<span style="padding-left: 15px;">({{addChangeForm.share_LISTED_NAME}})</span>
           </el-col>
           <el-col :span="11">
             <span style="font-weight:bold;">上年城投信息</span>
           </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="企业性质 :"  >
           <el-col :span="11">
            {{addChangeForm.org_TYPE_NAME}}
           </el-col>
           <el-col :span="11" v-if="addChangeForm.last_CITY_INVEST_NAME != '' & addChangeForm.last_CITY_INVEST_NAME != null">
             <span>是否城投 :</span>
             <span class="myspan">{{addChangeForm.last_CITY_INVEST_NAME}}</span>
           </el-col>
        </el-form-item>
        <el-form-item label="财报年份 :" >
          <el-col  :span="11">
            {{addChangeForm.fiscal_YEAR}}
          </el-col>
          <el-col :span="11" v-if="addChangeForm.last_REGION_NAME != '' & addChangeForm.last_REGION_NAME != null">
            <span>政府归属 :</span>
            <span class="myspan">{{addChangeForm.last_REGION_NAME}}</span>
           </el-col>
        </el-form-item>
        <el-form-item label="上年行业 :" >
          <el-col :span="11">
            {{addChangeForm.last_GICS_NAME === null ?'\xa0' : addChangeForm.last_GICS_NAME}}
          </el-col>
          <el-col :span="11" v-if="addChangeForm.last_spt_index_name1 != ''">
            <span>{{addChangeForm.last_spt_index_name1}} :</span>
            <span class="myspan">{{addChangeForm.last_spt_level_name1}}</span>
           </el-col>
        </el-form-item>
        <el-form-item label="财报行业 :" prop="gics_CODES"  >
          <el-col :span="11">
            <div class="block">
            <el-cascader :show-all-levels="false"
              placeholder="搜索"
              v-model="addChangeForm.gics_CODES"
              :options="fiscalOptions"
              filterable></el-cascader>
            </div>
          </el-col>
          <el-col :span="11" v-if="addChangeForm.last_spt_index_name2 != ''">
            <span>{{addChangeForm.last_spt_index_name2}} :</span>
            <span class="myspan">{{addChangeForm.last_spt_level_name2}}</span>
           </el-col>
        </el-form-item>
        <el-form-item label="主体归属 :" >
          <el-col :span="11">
            <div class="block">
              <el-cascader :show-all-levels="false"
                @change="regionsChange"
                placeholder="请选择"
                v-model="addChangeForm.regions"
              :props="{ checkStrictly: true }"
                :options="regionOptions"
                filterable></el-cascader>
            </div>
          </el-col>
          <el-col :span="11" v-if="addChangeForm.last_spt_index_name3 != ''">
            <span>{{addChangeForm.last_spt_index_name3}} :</span>
            <span class="myspan">{{addChangeForm.last_spt_level_name3}}</span>
           </el-col>
        </el-form-item>
        <!-- <el-form-item label="城投变更">
          <el-checkbox v-model="addChangeForm.change_CITY_INVEST">是否变更城投</el-checkbox>
        </el-form-item> -->
        <el-form-item label="城投核查 :">
          <el-col :span="11">
            <el-checkbox v-model="addChangeForm.check_CITY_INVEST" @change="checkCityChange">是否核查</el-checkbox>
          </el-col>
          <el-col :span="11" v-if="addChangeForm.last_spt_index_name4 != ''">
            <span>{{addChangeForm.last_spt_index_name4}} :</span>
            <span class="myspan">{{addChangeForm.last_spt_level_name4}}</span>
          </el-col>
        </el-form-item>
        <el-form-item label="城投变更 :" prop="change_CITY_INVEST">
          <el-col :span="11">
            <el-select v-model="addChangeForm.change_CITY_INVEST" @change="cityChange"  placeholder="请选择">
              <el-option
                v-for="item in violateChangeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="11" v-if="addChangeForm.last_SPT_SCORE != '' & addChangeForm.last_SPT_SCORE != null">
            <span>总分 :</span>
            <span class="myspan">{{addChangeForm.last_SPT_SCORE}}</span>
           </el-col>
        </el-form-item>
        <div v-if="addChangeForm.change_CITY_INVEST==1 && addChangeForm.check_CITY_INVEST" >
          <el-form-item label="是否城投 :" prop="city_INVEST">
            <el-select v-model="addChangeForm.city_INVEST" @change="isCityInvestChange"  placeholder="请选择">
              <el-option
                v-for="item in violateOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div v-if="addChangeForm.change_CITY_INVEST==1 && addChangeForm.city_INVEST==1" >
          <el-form-item label="政府归属 :" prop="region_CODES" >
            <div class="block">
              <el-cascader :show-all-levels="false"
              @change="regionCodesChange"
                placeholder="请选择"
                v-model="addChangeForm.region_CODES"
              :props="{ checkStrictly: true }"
                :options="regionOptions"
                filterable></el-cascader>
            </div>
          </el-form-item>
          <el-divider></el-divider>
          <el-form-item>
            <el-row>
              <el-col :span="10">支持维度</el-col>
              <el-col :span="6">支持程度</el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item>
                  公益性程度
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item prop="spt_level_value">
                <el-select v-model="addChangeForm.spt_level_value" @change="selectSptLevel" placeholder="请选择">
                  <el-option
                    v-for="item in spt_level_options"
                    :score="item.support_SCORE"
                    :key="item.support_LEVEL_ID"
                    :label="item.support_LEVEL_TITLE"
                    :value="item.support_LEVEL_ID">
                  </el-option>
                </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item>
                  股权结构
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item prop="spt_level_value2">
                <el-select v-model="addChangeForm.spt_level_value2" @change="selectSptLevel2" placeholder="请选择">
                  <el-option
                    v-for="item in spt_level_options2"
                    :score="item.support_SCORE"
                    :key="item.support_LEVEL_ID"
                    :label="item.support_LEVEL_TITLE"
                    :value="item.support_LEVEL_ID">
                  </el-option>
                </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item>
                  政府补助占营业利润总额的比重
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item prop="spt_level_value3">
                <el-select v-model="addChangeForm.spt_level_value3" @change="selectSptLevel3" placeholder="请选择">
                  <el-option
                    v-for="item in spt_level_options3"
                    :score="item.support_SCORE"
                    :key="item.support_LEVEL_ID"
                    :label="item.support_LEVEL_TITLE"
                    :value="item.support_LEVEL_ID">
                  </el-option>
                </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item>
                  增信措施
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item prop="spt_level_value4">
                <el-select v-model="addChangeForm.spt_level_value4" @change="selectSptLevel4" placeholder="请选择">
                  <el-option
                    v-for="item in spt_level_options4"
                    :score="item.support_SCORE"
                    :key="item.support_LEVEL_ID"
                    :label="item.support_LEVEL_TITLE"
                    :value="item.support_LEVEL_ID">
                  </el-option>
                </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="总分 :">{{addChangeForm.support_SCORE_All}}</el-form-item>
        </div>
        <el-form-item>
          <div style="text-align:center;">
            <el-button v-if="!isDisabledGics || !isDisabledVio" type="primary" @click="lunch('addChangeForm')">发起审核</el-button>
            <el-button v-if="!isTaskConfirm" type="primary" @click="submitTask('addChangeForm')">提交审核</el-button>
            <el-button v-if="!isTaskConfirm" type="primary" @click="cancleTask('addChangeForm')">取消任务</el-button>
            <el-button  @click="cancle">关闭</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="变更记录" :visible.sync="recordDialogVisible" width="50%" >
       <el-table
        :data="tableDataHistory"  highlight-current-row
        @current-change="companyCurrentChange"
        border stripe
        style="width: 100%">
        <el-table-column
          prop="fiscal_YEAR"
          label="财报年份"
          width="100">
        </el-table-column>
        <el-table-column
          prop="gics_NAME"
          label="财报行业"
          width="140">
        </el-table-column>
        <!-- <el-table-column
          prop="company_GICS_NAME"
          label="公司行业"
          width="140">
        </el-table-column> -->
        <el-table-column
          prop="is_PD"
          label="是否违约"
          width="140">
        </el-table-column>
        <el-table-column
          prop="city_INVEST"
          label="是否城投"
          width="140">
        </el-table-column>
        <el-table-column
          prop="region_NAME"
          label="政府归属"
          width="140">
        </el-table-column>
        <el-table-column
          prop="spt_SCORE"
          label="政府支持得分"
          width="110">
        </el-table-column>
       </el-table>
    </el-dialog>
    <el-dialog title="暂不处理" :visible.sync="suspendDialog"
    :before-close="cancleSuspendForm" width="50%" >
      <el-form ref="suspendForm" :model="suspendForm" label-width="100px" :rules="rules" class="demo-ruleForm" >
        <el-form-item label="GICS暂停" label-width="180px" prop="suspend_CHECK_GICS">
          <el-select v-model="suspendForm.suspend_CHECK_GICS"
          @change="gicsChange"
          placeholder="请选择">
            <el-option
              v-for="item in suspendOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="GICS暂停原因" label-width="180px" prop="suspend_GICS_OPINION">
          <el-input type="textarea"
          :disabled="this.suspendForm.suspend_CHECK_GICS === ''"
          rows="4"
          style="width: 550px"
          v-model="suspendForm.suspend_GICS_OPINION"></el-input>
        </el-form-item>
        <el-form-item label="城投暂停" label-width="180px" prop="suspend_CHECK_CITY_INVEST">
          <el-select v-model="suspendForm.suspend_CHECK_CITY_INVEST"
          @change="cityInvestChange"
          placeholder="请选择">
            <el-option
              v-for="item in suspendOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="城投暂停原因" label-width="180px" prop="suspend_CITY_OPINION">
          <el-input type="textarea"
          :disabled="this.suspendForm.suspend_CHECK_CITY_INVEST === ''"
          rows="4"
          style="width: 550px"
          v-model="suspendForm.suspend_CITY_OPINION"></el-input>
        </el-form-item>
        <el-form-item label-width="180px">
          <el-button type="primary" @click="submitSuspendForm('suspendForm')">确定</el-button>
          <el-button @click="cancleSuspendForm()">关闭</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'CompanyAdditional',
  methods: {
    async getTableData () {
      const { data: res } = await this.$http.get('querygics', {
        params: this.queryInfo
      })
      if (this.queryInfo.querySelectInput === 'gics') {
        this.isDisabledGics = false
        this.isDisabledVio = true
        this.isTaskConfirm = true
      } else if (this.queryInfo.querySelectInput === 'violate') {
        this.isDisabledGics = true
        this.isDisabledVio = false
        this.isTaskConfirm = true
      } else if (this.queryInfo.querySelectInput === 'back') {
        this.isDisabledGics = true
        this.isDisabledVio = true
        this.isTaskConfirm = false
      }
      this.tableData = res.list
      this.totle = res.total
      this.rowData = null
      this.addChangeForm.city_INVEST = null
    },
    async lunch (addChangeForm) {
      let istrue
      // // form验证
      this.$refs[addChangeForm].validate((valid) => {
        if (valid) {
          istrue = true
        } else {
          return false
        }
      })
      if (istrue) {
        this.addChangeForm.gics_CODE = this.addChangeForm.gics_CODES[3]
        const { data: res } = await this.$http.post('lunch', this.addChangeForm)
        if (res.have) {
          this.$message.success('该任务已被别的用户提交')
        }
        this.addDialogVisible = false
        this.getTableData()
      }
    },
    async cancleTask () {
      await this.$http.post('cancle/gics/task', {
        task_ID: this.addChangeForm.task_ID
      })
      this.addDialogVisible = false
      this.getTableData()
    },
    async submitTask () {
      await this.$http.post('submit/gics/task', this.addChangeForm)
      this.addDialogVisible = false
      this.getTableData()
    },
    async submitSuspendForm () {
      await this.$http.post('submit/suspend/form', this.suspendForm)
      this.cancleSuspendForm()
      this.getTableData()
    },
    cancleSuspendForm () {
      this.suspendDialog = false
      this.$refs.suspendForm.resetFields()
    },
    addDialogClosed () {
      this.$refs.addChangeForm.resetFields()
    },
    async getGics () {
      const { data: res } = await this.$http.get('gics')
      this.fiscalOptions = res
    },
    async getRegion () {
      const { data: res } = await this.$http.get('region')
      this.regionOptions = res
    },
    async selectLevel () {
      const { data: res } = await this.$http.get('get/spt/level', {
        params: { support_index_id: 10 }
      })
      this.spt_level_options = res
      const { data: res2 } = await this.$http.get('get/spt/level', {
        params: { support_index_id: 20 }
      })
      this.spt_level_options2 = res2
      const { data: res3 } = await this.$http.get('get/spt/level', {
        params: { support_index_id: 30 }
      })
      this.spt_level_options3 = res3
      const { data: res4 } = await this.$http.get('get/spt/level', {
        params: { support_index_id: 40 }
      })
      this.spt_level_options4 = res4
    },
    async selectSptLevel (value) {
      const spt = this.spt_level_options
      for (const iterator of spt) {
        if (iterator.support_LEVEL_ID === value) {
          this.addChangeForm.support_SCORE = iterator.support_SCORE
        }
      }
      this.addChangeForm.support_SCORE_All = this.sumSupportScore
    },
    async selectSptLevel2 (value) {
      const spt = this.spt_level_options2
      for (const iterator of spt) {
        if (iterator.support_LEVEL_ID === value) {
          this.addChangeForm.support_SCORE2 = iterator.support_SCORE
        }
      }
      this.addChangeForm.support_SCORE_All = this.sumSupportScore
    },
    async selectSptLevel3 (value) {
      const spt = this.spt_level_options3
      for (const iterator of spt) {
        if (iterator.support_LEVEL_ID === value) {
          this.addChangeForm.support_SCORE3 = iterator.support_SCORE
        }
      }
      this.addChangeForm.support_SCORE_All = this.sumSupportScore
    },
    async selectSptLevel4 (value) {
      const spt = this.spt_level_options4
      for (const iterator of spt) {
        if (iterator.support_LEVEL_ID === value) {
          this.addChangeForm.support_SCORE4 = iterator.support_SCORE
        }
      }
      this.addChangeForm.support_SCORE_All = this.sumSupportScore
    },
    // 监听 pagesize改变的事件
    handleSizeChange (newSize) {
      this.queryInfo.pagesize = newSize
      this.getTableData()
    },
    // 监听 页码值 改变事件
    handleCurrentChange (newSize) {
      this.queryInfo.pagenum = newSize
      this.getTableData()
    },
    // 确认行业
    async comfirmGics () {
      // 行业数据赋值
      this.getGics()
      this.getRegion()
      if (this.rowData == null) {
        this.$alert('请选择一条公司信息', '信息选择').then(() => {
        }).catch(() => {
        })
        return
      }
      this.selectLevel()
      // 根据公司ID和财报年份查询或者根据任务ID查询
      const { data: res } = await this.$http.get('/query/info/by/id', {
        params: {
          task_ID: this.addChangeForm.task_ID,
          company_ID: this.addChangeForm.company_ID,
          fiscal_YEAR: this.addChangeForm.fiscal_YEAR
        }
      })
      // 默认核查城投时城投信息不赋值否则赋值
      if (res !== '' && res !== null) {
        this.oldData = res
        this.addChangeForm.gics_CODES = res.gics_CODES
        this.addChangeForm.regions = res.regions
        this.addChangeForm.last_GICS_NAME = res.last_GICS_NAME
        this.addChangeForm.org_TYPE_NAME = res.org_TYPE_NAME
        this.addChangeForm.share_LISTED_NAME = res.share_LISTED_NAME
        this.addChangeForm.last_CITY_INVEST_NAME = res.last_CITY_INVEST_NAME
        this.addChangeForm.last_REGION_NAME = res.last_REGION_NAME
        this.addChangeForm.last_SPT_SCORE = res.last_SPT_SCORE
        // 城投信息
        this.addChangeForm.region_CODES = res.region_CODES
        if (this.queryInfo.querySelectInput === 'gics') {
          this.addChangeForm.check_CITY_INVEST = false
        } else if (this.queryInfo.querySelectInput === 'violate') {
          this.addChangeForm.check_CITY_INVEST = true
        } else {
          if (res.check_CITY_INVEST === 1) {
            this.addChangeForm.check_CITY_INVEST = true
          } else {
            this.addChangeForm.check_CITY_INVEST = false
          }
        }
        if (res.change_CITY_INVEST !== null && res.change_CITY_INVEST !== '') {
          this.addChangeForm.change_CITY_INVEST = res.change_CITY_INVEST + ''
        } else if (this.queryInfo.querySelectInput === 'violate') {
          this.addChangeForm.change_CITY_INVEST = '1'
        } else {
          this.addChangeForm.change_CITY_INVEST = '0'
        }
        if (res.city_INVEST !== null && res.city_INVEST !== '') {
          this.addChangeForm.city_INVEST = res.city_INVEST + ''
        } else {
          this.addChangeForm.city_INVEST = ''
        }
        if (res.taSptDetall != null && res.taSptDetall.length > 0) {
          for (const item of res.taSptDetall) {
            if (item.support_INDEX_ID === 10) {
              this.addChangeForm.spt_level_value = item.support_LEVEL_ID
              this.addChangeForm.support_SCORE = item.support_SCORE
            } else if (item.support_INDEX_ID === 20) {
              this.addChangeForm.spt_level_value2 = item.support_LEVEL_ID
              this.addChangeForm.support_SCORE2 = item.support_SCORE
            } else if (item.support_INDEX_ID === 30) {
              this.addChangeForm.spt_level_value3 = item.support_LEVEL_ID
              this.addChangeForm.support_SCORE3 = item.support_SCORE
            } else if (item.support_INDEX_ID === 40) {
              this.addChangeForm.spt_level_value4 = item.support_LEVEL_ID
              this.addChangeForm.support_SCORE4 = item.support_SCORE
            }
          }
        } else {
          this.addChangeForm.spt_level_value = ''
          this.addChangeForm.spt_level_value2 = ''
          this.addChangeForm.spt_level_value3 = ''
          this.addChangeForm.spt_level_value4 = ''
          this.addChangeForm.support_SCORE = ''
          this.addChangeForm.support_SCORE2 = ''
          this.addChangeForm.support_SCORE3 = ''
          this.addChangeForm.support_SCORE4 = ''
        }
        if (this.queryInfo.querySelectInput === 'violate') { // 核查城投城投变更清空城投信息
          this.cleanCityInfo()
        }
        // 上一年份城投信息显示
        if (res.lastSptDetall != null && res.lastSptDetall.length > 0) {
          for (const item of res.lastSptDetall) {
            if (item.support_INDEX_ID === 10) {
              this.addChangeForm.last_spt_level_name1 = item.support_LEVEL_NAME
              this.addChangeForm.last_spt_index_name1 = item.support_INDEX_NAME
            } else if (item.support_INDEX_ID === 20) {
              this.addChangeForm.last_spt_level_name2 = item.support_LEVEL_NAME
              this.addChangeForm.last_spt_index_name2 = item.support_INDEX_NAME
            } else if (item.support_INDEX_ID === 30) {
              this.addChangeForm.last_spt_level_name3 = item.support_LEVEL_NAME
              this.addChangeForm.last_spt_index_name3 = item.support_INDEX_NAME
            } else if (item.support_INDEX_ID === 40) {
              this.addChangeForm.last_spt_level_name4 = item.support_LEVEL_NAME
              this.addChangeForm.last_spt_index_name4 = item.support_INDEX_NAME
            }
          }
        } else {
          this.addChangeForm.last_spt_index_name1 = ''
          this.addChangeForm.last_spt_index_name2 = ''
          this.addChangeForm.last_spt_index_name3 = ''
          this.addChangeForm.last_spt_index_name4 = ''
          this.addChangeForm.last_spt_level_name1 = ''
          this.addChangeForm.last_spt_level_name2 = ''
          this.addChangeForm.last_spt_level_name3 = ''
          this.addChangeForm.last_spt_level_name4 = ''
        }
        this.addChangeForm.support_SCORE_All = this.sumSupportScore
      }
      this.addDialogVisible = true
    },
    async comfirmViolate () {
      // 行业数据赋值
      this.getGics()
      this.getRegion()
      this.addChangeForm.city_INVEST = '1'
      if (this.rowData == null) {
        this.$alert('请选择一条公司信息', '信息选择').then(() => {
        }).catch(() => {
        })
        return
      }
      this.selectLevel()
      this.addDialogVisible = true
    },
    // 信息变更记录查询
    async handleClick (row) {
      const { data: res } = await this.$http.get('querygics/history', {
        params: { company_ID: row.company_ID }
      })
      this.tableDataHistory = res
      this.recordDialogVisible = true
    },
    async suspendClick (row) {
      this.suspendForm.company_ID = row.company_ID
      this.suspendForm.fiscal_YEAR = row.fiscal_YEAR
      const { data: res } = await this.$http.get('query/suspend/by/id/And/Year', {
        params: { company_ID: row.company_ID, fiscal_YEAR: row.fiscal_YEAR }
      })
      if (res !== '') {
        res.suspend_CHECK_CITY_INVEST = res.suspend_CHECK_CITY_INVEST + ''
        res.suspend_CHECK_GICS = res.suspend_CHECK_GICS + ''
        this.suspendForm = res
      }
      this.suspendDialog = true
    },
    gicsChange () {
      if (this.suspendForm.suspend_CHECK_GICS === '1') {
        this.rules.suspend_GICS_OPINION[0].required = true
      } else {
        this.rules.suspend_GICS_OPINION[0].required = false
      }
      if (this.suspendForm.suspend_CHECK_GICS === '') {
        this.suspendForm.suspend_GICS_OPINION = ''
      }
    },
    cityInvestChange () {
      if (this.suspendForm.suspend_CHECK_CITY_INVEST === '1') {
        this.rules.suspend_CITY_OPINION[0].required = true
      } else {
        this.rules.suspend_CITY_OPINION[0].required = false
      }
      if (this.suspendForm.suspend_CHECK_CITY_INVEST === '') {
        this.suspendForm.suspend_CITY_OPINION = ''
      }
    },
    // 点击一条公司信息
    companyCurrentChange (val) {
      if (val != null) {
        if (val.task_ID !== 0) {
          this.addChangeForm.task_ID = val.task_ID
        } else {
          this.addChangeForm.task_ID = ''
        }
        // this.addChangeForm.city_INVEST = val.city_INVEST
        this.addChangeForm.company_NAME = val.company_NAME
        this.addChangeForm.company_ID = val.company_ID
        this.addChangeForm.fiscal_YEAR = val.fiscal_YEAR
        // this.addChangeForm.is_CITY = val.is_CITY
        // this.addChangeForm.gics_CODE = val.gics_CODE
        // this.addChangeForm.orignal_GICS_CODE = val.gics_CODE
        // 设置查询条件从接口单独查询某个主体信息
        this.rowData = val
      }
    },
    cityChange (val) { // 城投变更按钮
      if (val === '0' && this.addChangeForm.check_CITY_INVEST) {
        const res = this.oldData
        if (res.city_INVEST !== null && res.city_INVEST !== '') {
          this.addChangeForm.city_INVEST = res.city_INVEST + ''
        } else {
          this.addChangeForm.city_INVEST = ''
        }
        this.addChangeForm.region_CODES = res.region_CODES
        this.addChangeForm.support_SCORE_All = res.spt_SCORE
        if (res.taSptDetall != null && res.taSptDetall.length > 0) {
          for (const item of res.taSptDetall) {
            if (item.support_INDEX_ID === 10) {
              this.addChangeForm.spt_level_value = item.support_LEVEL_ID
              this.addChangeForm.support_SCORE = item.support_SCORE
            } else if (item.support_INDEX_ID === 20) {
              this.addChangeForm.spt_level_value2 = item.support_LEVEL_ID
              this.addChangeForm.support_SCORE2 = item.support_SCORE
            } else if (item.support_INDEX_ID === 30) {
              this.addChangeForm.spt_level_value3 = item.support_LEVEL_ID
              this.addChangeForm.support_SCORE3 = item.support_SCORE
            } else if (item.support_INDEX_ID === 40) {
              this.addChangeForm.spt_level_value4 = item.support_LEVEL_ID
              this.addChangeForm.support_SCORE4 = item.support_SCORE
            }
          }
        } else {
          this.cleanCitySptInfo() // 清空城投支持信息
        }
      } else {
        this.cleanCityInfo()
        this.addChangeForm.check_CITY_INVEST = true
      }
    },
    checkCityChange (value) { // 城投核查按钮
      if (value) { // 核查
        this.cityChange('0')
      } else { // 不核查 清空城投信息
        this.addChangeForm.change_CITY_INVEST = '0' // 不核查城投信息不变更
        this.cleanCityInfo()
      }
    },
    isCityInvestChange (val) { // 是否城投
      if (val === '0') {
        this.cleanCityInfo()
        this.addChangeForm.city_INVEST = '0'
      } else {
        this.addChangeForm.region_CODES = this.oldData.region_CODES
      }
    },
    regionCodesChange (val) {
      this.addChangeForm.regions = val
    },
    regionsChange (val) {
      if (this.addChangeForm.check_CITY_INVEST && this.addChangeForm.change_CITY_INVEST === '1' && this.addChangeForm.city_INVEST === '1') {
        this.addChangeForm.region_CODES = val
      } else {
        this.addChangeForm.region_CODES = []
      }
    },
    cleanCityInfo () { // 清空城投信息
      this.addChangeForm.region_CODES = '' // 政府归属
      this.addChangeForm.city_INVEST = '' // 是否城投
      // this.addChangeForm.region_CODE = ''
      this.cleanCitySptInfo()
    },
    cleanCitySptInfo () { // 清空城投支持
      this.addChangeForm.support_SCORE = ''
      this.addChangeForm.support_SCORE2 = ''
      this.addChangeForm.support_SCORE3 = ''
      this.addChangeForm.support_SCORE4 = ''
      this.addChangeForm.spt_level_value = ''
      this.addChangeForm.spt_level_value2 = ''
      this.addChangeForm.spt_level_value3 = ''
      this.addChangeForm.spt_level_value4 = ''
      this.cleanCitySptScoreAll()
    },
    cleanCitySptScoreAll () {
      this.addChangeForm.support_SCORE_All = ''
    },
    // 取消并清空数据
    cancle () {
      this.addDialogVisible = false
    },
    // 关闭并清空数据
    closeRegDialog () {
      this.addDialogVisible = false
    },
    lastYear () {
      const date = new Date()
      date.setFullYear(date.getFullYear() - 1)
      const moment = require('moment')
      return moment(date).format('yyyy')
    }
  },
  computed: {
    sumSupportScore: function () {
      if (this.addChangeForm.support_SCORE === null || this.addChangeForm.support_SCORE2 === null || this.addChangeForm.support_SCORE3 === null || this.addChangeForm.support_SCORE4 === null) {
        return null
      } else if (this.addChangeForm.support_SCORE === '' || this.addChangeForm.support_SCORE2 === '' || this.addChangeForm.support_SCORE3 === '' || this.addChangeForm.support_SCORE4 === '') {
        return null
      } else {
        return Number(this.addChangeForm.support_SCORE) + Number(this.addChangeForm.support_SCORE2) + Number(this.addChangeForm.support_SCORE3) + Number(this.addChangeForm.support_SCORE4)
      }
    }
  },
  data () {
    return {
      input: '',
      isDisabledGics: false,
      isDisabledVio: true,
      isTaskConfirm: true,
      queryInfo: {
        query: '',
        // 筛选条件查询
        querySelectInput: 'gics',
        querySelect: '',
        query_YEAR: this.lastYear(),
        is_CHECK: 'no',
        bond_RAISE: '',
        // 当前页数
        pagenum: 1,
        // 每页显示多少数据
        pagesize: 10
      },
      addChangeForm: {
        company_NAME: '',
        company_ID: '',
        task_ID: '',
        gics_CODES: '',
        gics_CODE: '',
        // region_CODE: '',
        region_CODES: '',
        regions: '',
        fiscal_YEAR: '',
        is_CITY: '',
        check_CITY_INVEST: '',
        support_SCORE: '',
        support_SCORE2: '',
        support_SCORE3: '',
        support_SCORE4: '',
        city_INVEST: null,
        change_CITY_INVEST: null,
        support_SCORE_All: null,
        spt_level_value: '',
        spt_level_value2: '',
        spt_level_value3: '',
        spt_level_value4: '',
        last_GICS_NAME: '',
        org_TYPE_NAME: '',
        share_LISTED_NAME: '',
        lastSptDetall: '',
        last_spt_level_name1: '',
        last_spt_level_name2: '',
        last_spt_level_name3: '',
        last_spt_level_name4: '',
        last_spt_index_name1: '',
        last_spt_index_name2: '',
        last_spt_index_name3: '',
        last_spt_index_name4: '',
        last_CITY_INVEST_NAME: '',
        last_REGION_NAME: '',
        last_SPT_SCORE: ''
      },
      suspendForm: {
        company_ID: '',
        fiscal_YEAR: '',
        suspend_CHECK_GICS: '',
        suspend_CHECK_CITY_INVEST: '',
        suspend_GICS_OPINION: '',
        suspend_CITY_OPINION: ''
      },
      queryForm: {
        task_ID: null
      },
      totle: 0,
      // 添加用户对话框
      addDialogVisible: false,
      recordDialogVisible: false,
      suspendDialog: false,
      tableData: [],
      tableDataHistory: [],
      rowData: null,
      oldData: null,
      spt_options: [],
      spt_level_options2: [],
      spt_level_options3: [],
      spt_level_options4: [],
      spt_level_options: [{
        value: '10',
        label: '公益性程度'
      },
      {
        value: '20',
        label: '股权结构'
      },
      {
        value: '30',
        label: '政府补助占营业利润总额的比重'
      },
      {
        value: '40',
        label: '增信措施'
      }],
      options: [{
        value: '选项1',
        label: '2016'
      }, {
        value: '选项2',
        label: '2017'
      }, {
        value: '选项3',
        label: '2018'
      }, {
        value: '选项4',
        label: '2019'
      }],
      querySelectOptions: [{
        value: 'gics',
        label: '行业筛选'
      }, {
        value: 'violate',
        label: '城投筛选'
      }, {
        value: 'back',
        label: '退回任务'
      }],
      queryCheckOptions: [{
        value: 'yes',
        label: '已核查'
      }, {
        value: 'no',
        label: '未核查'
      }, {
        value: 'suspend',
        label: '暂不确认'
      }, {
        value: 'all',
        label: '全部'
      }],
      queryCityOptions: [{
        value: 'yes',
        label: '是'
      }, {
        value: 'no',
        label: '否'
      }, {
        value: 'all',
        label: '全部'
      }],
      bondRaiseOptions: [{
        value: 'yes',
        label: '有存续债'
      }, {
        value: 'no',
        label: '无存续债'
      }, {
        value: 'all',
        label: '全部'
      }],
      fiscalOptions: [{
        value: '10',
        label: '能源',
        children: [{
          value: '1010',
          label: '能源',
          children: [{
            value: '101010',
            label: '能源设备与服务',
            children: []
          }, {
            value: '101020',
            label: '石油、天然气与消费用燃料',
            children: [{
              value: '10102010',
              label: '综合性石油与天然气企业'
            }]
          }]
        }]
      }],
      regionOptions: [],
      violateOptions: [{
        value: '1',
        label: '是'
      }, {
        value: '0',
        label: '否'
      }],
      violateChangeOptions: [{
        value: '1',
        label: '是'
      }, {
        value: '0',
        label: '否'
      }],
      suspendOptions: [{
        value: '1',
        label: '是'
      }, {
        value: '0',
        label: '否'
      }, {
        value: '',
        label: '请选择'
      }],
      value: '',
      rules: {
        gics_CODES: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        city_INVEST: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        change_CITY_INVEST: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        region_CODES: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        spt_level_value: [
          { required: false, message: ' ', trigger: 'change' }
        ],
        spt_level_value2: [
          { required: false, message: ' ', trigger: 'change' }
        ],
        spt_level_value3: [
          { required: false, message: ' ', trigger: 'change' }
        ],
        spt_level_value4: [
          { required: false, message: ' ', trigger: 'change' }
        ],
        suspend_GICS_OPINION: [
          { required: false, message: ' ', trigger: 'blur' }
        ],
        suspend_CITY_OPINION: [
          { required: false, message: ' ', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getTableData()
  }
}
</script>

<style>
.el-dialog{
    display: flex;
    flex-direction: column;
    margin:0 !important;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    /*height:600px;*/
    max-height:calc(100% - 30px);
    max-width:calc(100% - 30px);
}
.el-dialog .el-dialog__body{
    flex:1;
    overflow: auto;
}
.el-table__body tr.current-row>td {
    color: #fff;
    background-color: #a2a4a7!important;
}
.el-row {
    margin-bottom: 20px;
  }
  .el-row:last-child {
    margin-bottom: 0;
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
  .el-form-item {
    margin-top: -20px
  }
  .myspan {
    padding-left: 12px;
    color:blue;
  }
</style>
